import { useCallback, useState } from 'react';
import { WSActType } from '../typesWorkSite';
import { useWorkSiteContext } from '../WorkSiteSummaryWrapper';
import { useStore } from '30.quickConnect.Stores';

type WorkSiteSummaryDataProps = { onSave: () => void; buttonIsLoading: boolean };

const useSaveButtonData = (): WorkSiteSummaryDataProps => {
  const { workSite, setWorkSite } = useWorkSiteContext();
  const { WorkSiteStore } = useStore();
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const onSave = useCallback(() => {
    // TODO KTY sent all data to the server
    console.log('Sending Worksite to server:', workSite);
    setButtonIsLoading(true);
    WorkSiteStore.saveWorkSite(workSite)
      .then(() => {
        //on Success
        setWorkSite({ type: WSActType.HASCHANGED, value: false });
      })
      .finally(() => {
        console.log('done');
        setButtonIsLoading(false);
      });
  }, [WorkSiteStore, setWorkSite, workSite]);

  return { onSave, buttonIsLoading };
};

export default useSaveButtonData;

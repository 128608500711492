import React, { FunctionComponent, useState } from 'react';
import { Button, DialogContent, DialogTitle, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useTranslation } from 'react-i18next';
import CreateNewUserDialog from './CreateNewUserDialog';
import AddOneOrMultipleUsersDialog from './AddOneOrMultipleUsersDialog';

export type AddNewUserDialogProps = {
  onClose: () => void;
};

const ChooseNewOrExistingAddDialog: FunctionComponent<AddNewUserDialogProps> = (props: AddNewUserDialogProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const [existingAdd, setExistingAdd] = useState<boolean>(false);
  const [newAdd, setNewAdd] = useState<boolean>(false);

  return (
    <>
      {!existingAdd && !newAdd && (
        <>
          <DialogTitle>{t('work_site_users_dialog_add_or_create_title')}</DialogTitle>
          <DialogContent>
            <Stack direction={'row'} data-cy="AddOneOrMultipleUsersDialog" gap={'1rem'}>
              <Button
                fullWidth
                variant={'contained'}
                style={{ padding: '1rem' }}
                size={'large'}
                onClick={() => setExistingAdd(true)}
              >
                <Stack gap={'0.5rem'} alignItems={'center'}>
                  <SearchIcon fontSize={'large'} />
                  {t('work_site_users_dialog_add_or_create_add')}
                </Stack>
              </Button>
              <Button
                fullWidth
                variant={'outlined'}
                style={{ padding: '1rem' }}
                size={'large'}
                onClick={() => setNewAdd(true)}
              >
                <Stack gap={'0.5rem'} alignItems={'center'}>
                  <PersonAddAlt1Icon fontSize={'large'} />
                  {t('work_site_users_dialog_add_or_create_create')}
                </Stack>
              </Button>
            </Stack>
          </DialogContent>
        </>
      )}
      {existingAdd && <AddOneOrMultipleUsersDialog onClose={props.onClose} />}
      {newAdd && <CreateNewUserDialog onClose={props.onClose} />}
    </>
  );
};

export default ChooseNewOrExistingAddDialog;

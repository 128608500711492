import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { HOME, LOGIN_ADAL, MODULE_CHOICE } from './paths';
import { LinkRouteType } from './types';
import { ANONYMOUS_ROUTES, USER_ROUTES } from './routes';
import { ModuleProvider } from '00.app/module/moduleContext';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { useStore } from '30.quickConnect.Stores';

const RoutesBuilder = () => {
  // On récupère le store
  const {
    initQuickConnectApp,
    LoginStore: {
      connected,
      signInInfos: { userUPN, module },
      connectByMsal,
      availableModules,
    },
    DeclarationStore: { findBackUpsByUserUPNAsync },
    SessionStore: { setLastURLSaved },
  } = useStore();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [moduleWasChosen, setModuleWasChosen] = useState<boolean>(false);

  // On ajoute des hooks
  useEffect(() => {
    if (!connected && location.pathname !== HOME && location.pathname !== LOGIN_ADAL) {
      // Si on n'est pas connecté on renvoie vers le LOGIN
      if (pathname !== HOME) {
        setLastURLSaved(pathname + location.search);
        navigate(HOME);
      }
    }
  }, [connected, location.pathname, navigate, pathname, setLastURLSaved, location.search]);

  // Redirect to module choice login after msal auth if modules are available
  useEffect(() => {
    if (connected && connectByMsal && availableModules && Object.keys(availableModules).length > 1) {
      if (!moduleWasChosen) {
        navigate(MODULE_CHOICE);
        setModuleWasChosen(true);
        return;
      }
      if (moduleWasChosen && module) {
        setLastURLSaved('');
        navigate(HOME);
      }
    }
  }, [connectByMsal, connected, module, moduleWasChosen, navigate, setLastURLSaved, availableModules]);

  useEffect(() => {
    (async () => {
      if (connected) {
        if (module === AppModulesIDs.MODULE_PARAM_WORK_SITE_ID) {
          return;
        }
        await initQuickConnectApp();
        // TODO: Voir pour désactiver la rech. dans la sauvegarde auto dans le cas où on passe par le DeepLink
        await findBackUpsByUserUPNAsync(userUPN);
      }
    })();
  }, [
    connected,
    initQuickConnectApp,
    findBackUpsByUserUPNAsync,
    userUPN,
    module,
    navigate,
    connectByMsal,
    moduleWasChosen,
  ]);

  const routesBuilderMemo = useMemo<LinkRouteType[]>(() => (connected ? USER_ROUTES : ANONYMOUS_ROUTES), [connected]);
  const routesBuilder = useRoutes(routesBuilderMemo);
  return <ModuleProvider value={module}>{routesBuilder}</ModuleProvider>;
};

export default observer(RoutesBuilder);

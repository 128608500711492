import { useState } from 'react';
import { useStore } from '30.quickConnect.Stores';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

export enum WorkSiteStatus {
  COMPLETED,
  INCOMPLETE,
  CLOSED,
}

export type SimpleWorkSite = {
  id: string;
  name: string;
  status: WorkSiteStatus;
  published: boolean;
  icon: string;
};

type HomeContentProps = {
  workSiteList: Array<SimpleWorkSite>;
};

const useWorkSiteListData = (): HomeContentProps => {
  const { WorkSiteStore } = useStore();
  const [workSiteList, setWorkSiteList] = useState<Array<SimpleWorkSite>>([]);

  useEffectOnce(() => {
    /*TODO KTY faire un call api pour recup la liste des chantiers*/
    WorkSiteStore.getAllWorkSite().then((res) => {
      setWorkSiteList(res);
    });
  });
  return { workSiteList: workSiteList };
};

export default useWorkSiteListData;

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem, Stack, Zoom } from '@mui/material';
import {
  CloseOutlined,
  GroupAddOutlined,
  GroupsOutlined,
  Save,
  SettingsOutlined,
  DownloadDone,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router';
import { useTopBarWorkSiteContextSetter, useWorkSiteContext } from '../WorkSiteSummaryWrapper';
import useSaveButtonData from './hookSaveButton';

const SaveButton: FunctionComponent = () => {
  const { onSave, buttonIsLoading } = useSaveButtonData();
  const { workSite } = useWorkSiteContext();
  const { t } = useTranslation('moduleWorkSite');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setTopBar = useTopBarWorkSiteContextSetter();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const wasSaving = useRef(false);

  useEffect(() => {
    const saveIcon = () => {
      const updateLastValue = () => {
        if (workSite.hasChanged) {
          wasSaving.current = false;
        }
        if (!buttonIsLoading && wasSaving.current) {
          return;
        }
        wasSaving.current = buttonIsLoading;
      };
      updateLastValue();

      const showSaveDone = !buttonIsLoading && wasSaving.current;

      return (
        <>
          <Zoom in={!showSaveDone} appear={false}>
            <Save />
          </Zoom>
          <Zoom in={showSaveDone} style={{ position: 'absolute', fontSize: '20px' }}>
            <DownloadDone />
          </Zoom>
        </>
      );
    };

    setTopBar(
      <Stack direction={'row'} gap={1}>
        <LoadingButton loading={buttonIsLoading} onClick={onSave} startIcon={saveIcon()} variant={'contained'}>
          {!buttonIsLoading && wasSaving.current
            ? t('work_site_summary_action_saved')
            : t('work_site_summary_action_save')}
        </LoadingButton>
        <IconButton onClick={handleClick}>
          <SettingsOutlined />
        </IconButton>
      </Stack>,
    );
  }, [buttonIsLoading, onSave, setTopBar, t, workSite.hasChanged]);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'basic-button' }}
    >
      <MenuItem onClick={() => navigate('./team')}>
        <ListItemIcon>
          <GroupsOutlined />
        </ListItemIcon>
        {t('work_site_summary_team_manage')}
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <GroupAddOutlined />
        </ListItemIcon>
        {t('work_site_summary_team_add')}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <CloseOutlined />
        </ListItemIcon>
        {t('work_site_summary_action_close_worksite')}
      </MenuItem>
    </Menu>
  );
};

export default SaveButton;

import { useState } from 'react';
import { toJS } from 'mobx';
import { UseGetImagesSources } from '../types';
import { useStore } from '30.quickConnect.Stores';
import { useImagesGroupContext } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/contexts/imagesGroupContext';
import { errorHandler } from '80.quickConnect.Core/helpers';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

const TAG =
  '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/hooks/useGetImagesGroupSources.ts';

const TOKEN_IMAGE = `${process.env.REACT_APP_IMAGESBANK_TOKEN}`;
const REMOTE_URL = `${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_IMAGESBANK_CONTAINER}`;

export function useGetImagesSources(endPoint: string): UseGetImagesSources {
  // on récupère les infos des contextes
  const {
    imagesStateInfos: { imagesBankId },
  } = useImagesGroupContext();

  const [srcSet, setSrcSet] = useState<string>('');

  const {
    LoginStore: { urlToUse },
  } = useStore();

  useEffectOnce(() => {
    (async () => {
      try {
        // const baseRemoteImageUrl = toJS(urlToUse)?.replace('https://', 'https://imagesgroup.').replace('/api/', '');
        const baseRemoteImageUrl = REMOTE_URL;
        const token = TOKEN_IMAGE;
        const url = `${baseRemoteImageUrl}/${imagesBankId}/img/original/${endPoint}?${token}`;

        setSrcSet(url);
      } catch (error) {
        errorHandler(TAG, error, 'useEffectOnce');
      }
    })();
    // Appel au service DeclarationStore pour récupérer la source de l'image.
  });

  return {
    srcSet,
  };
}

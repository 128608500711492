import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { WORK_SITE_SELECT, FORM } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import RootStore from '30.quickConnect.Stores/RootStore';

type ModuleType = AppModulesIDs | undefined;

const ModuleContext = createContext<ModuleType>(undefined);
const ModuleDispatchContext = createContext<(mod: ModuleType, redirect?: boolean) => void>(() => {});

export const useModuleContext = (): ModuleType => {
  return useContext(ModuleContext);
};

export function useModuleContextSetter() {
  return useContext(ModuleDispatchContext);
}

export const ModuleProvider = ({ children, value }: { value: ModuleType; children: React.ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const [module, setModule] = useState<ModuleType>(value);
  const {
    LoginStore: { setCurrentModule },
  } = useStore();

  async function onModuleChange(newModule: ModuleType, redirect?: boolean) {
    const fallback = () => {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID);
      setCurrentModule(AppModulesIDs.MODULE_WEB_CLIENT_ID);
      navigate(FORM);
    };
    const modules = await RootStore.getInstance().LoginStore.updateAvailableModules();
    if (modules === undefined) {
      fallback();
      return;
    }

    const hasAccessToModule = Object.keys(modules).filter((key) => modules[key] === newModule).length > 0;

    if (hasAccessToModule) {
      setModule(newModule);
      setCurrentModule(newModule || AppModulesIDs.MODULE_WEB_CLIENT_ID);
      if (!redirect) {
        return;
      }
      if (newModule === AppModulesIDs.MODULE_PARAM_WORK_SITE_ID) {
        navigate(WORK_SITE_SELECT);
        return;
      }
      if (newModule === AppModulesIDs.MODULE_WEB_CLIENT_ID) {
        navigate(FORM);
        return;
      }
    }
    fallback();
  }

  return (
    <ModuleContext.Provider value={module}>
      <ModuleDispatchContext.Provider value={onModuleChange}>{children}</ModuleDispatchContext.Provider>
    </ModuleContext.Provider>
  );
};

import axios from 'axios';
import { action, makeAutoObservable } from 'mobx';
import { stopPersisting } from 'mobx-persist-store';
import LoginStore from './LoginStore';
import DeclarationStore from './DeclarationStore';
import QCScriptStore from './QCScriptStore';
import DialogStore from './DialogStore';
import CommonStore from './CommonStore';
import SessionStore from './SessionStore';
import UserSettingsStore from './UserSettingsStore';
import EquipmentsStore from './EquipmentsStore';
import SharedListStore from './SharedListStore';
import InboxesStore from './InboxesStore';
import FormStore from './FormStore';
import ComputeStore from './ComputeStore';
import LoginMsalStore from './LoginStore/LoginMsalStore';
import { ConsentStore } from './ConsentStore';
import WorkSiteStore from './WorksiteStore/workSiteStore';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { countRecords, resetAllStorage } from '40.quickConnect.DataAccess/helpers';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import AxiosClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/axios/index';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

class RootStore {
  private static instance: RootStore | null = null;

  LoginStore: LoginStore;

  LoginMsalStore: LoginMsalStore;

  FormStore: FormStore;

  DeclarationStore: DeclarationStore;

  QCScriptStore: QCScriptStore;

  DialogStore: DialogStore;

  CommonStore: CommonStore;

  SessionStore: SessionStore;

  UserSettingsStore: UserSettingsStore;

  EquipmentsStore: EquipmentsStore;

  SharedListStore: SharedListStore;

  InboxesStore: InboxesStore;

  ComputeStore: ComputeStore;

  ConsentStore: ConsentStore;

  WorkSiteStore: WorkSiteStore;

  logger: CustomLogger;

  clientHTTP: IClientHTTP;

  /**
   *
   */
  private constructor(logger: CustomLogger) {
    this.logger = logger;
    makeAutoObservable(this, {
      clearAllDomainStores: action,
    });

    // Initialisation pour les requêtes HTTP
    this.clientHTTP = AxiosClientHTTP.getInstance(axios, this);

    this.LoginStore = new LoginStore(this, logger, 'LoginStore');
    this.LoginMsalStore = new LoginMsalStore(this, logger, 'LoginMsalStore');
    this.SessionStore = new SessionStore(this, logger);

    // Domain Stores
    this.FormStore = new FormStore(this, this.logger);
    this.DeclarationStore = new DeclarationStore(this, this.logger);
    this.QCScriptStore = new QCScriptStore(this, this.logger);
    this.DialogStore = new DialogStore(this, this.logger);
    this.CommonStore = new CommonStore(this, this.logger);
    this.UserSettingsStore = new UserSettingsStore(this, this.logger, 'UserSettingsStore');
    this.EquipmentsStore = new EquipmentsStore(this, logger);
    this.SharedListStore = new SharedListStore(this, logger);
    this.InboxesStore = new InboxesStore(this, logger);
    this.ComputeStore = new ComputeStore(this, this.logger);
    this.ConsentStore = new ConsentStore(this, this.logger);
    this.WorkSiteStore = new WorkSiteStore(this, this.logger);
  }

  public static getInstance = (): RootStore => {
    if (RootStore.instance === null) RootStore.instance = new RootStore(CustomLogger.getInstance());

    return RootStore.instance;
  };

  create = (): RootStore => this;

  resetAllObservables = () => {
    // Authentification stores
    this.LoginStore.resetStore();
    this.LoginMsalStore.resetStore();
    this.SessionStore.resetStore();

    // Domain stores
    this.FormStore.resetStore();
    this.DeclarationStore.resetStore();
    this.QCScriptStore.resetStore();
    this.DialogStore.resetStore();
    this.CommonStore.resetStore();
    this.UserSettingsStore.resetStore();
    this.EquipmentsStore.resetStore();
    this.SharedListStore.resetStore();
    this.InboxesStore.resetStore();
    this.ComputeStore.resetStore();
    this.ConsentStore.resetStore();
  };

  clearAllDomainStores = async () => {
    this.DeclarationStore.setShouldAbortRequests(true);
    this.DeclarationStore.clearDraftsAndHistoricItems();
    stopPersisting(this.DialogStore);
    stopPersisting(this.DeclarationStore);
    stopPersisting(this.QCScriptStore);
    stopPersisting(this.DeclarationStore.DraftsStore);
    stopPersisting(this.DeclarationStore.HistoryStore);
    stopPersisting(this.DeclarationStore.LeftMenuDraftsStore);
    stopPersisting(this.DeclarationStore.LeftMenuHistoryStore);
    stopPersisting(this.CommonStore);
    stopPersisting(this.FormStore);
    stopPersisting(this.UserSettingsStore);
    stopPersisting(this.InboxesStore);
    stopPersisting(this.EquipmentsStore);
    stopPersisting(this.SharedListStore);
    stopPersisting(this.UserSettingsStore);
    stopPersisting(this.ComputeStore);
    stopPersisting(this.ConsentStore);
    await resetAllStorage(this.logger);
  };

  sendLocalDataBaseRecordsCount = async (syncDate: string): Promise<void> => {
    if (!DateTimeExtension.isPast24Hours(syncDate)) return;

    await countRecords(this.logger);

    this.UserSettingsStore.setSyncDate(new Date());
  };

  initQuickConnectApp = async (): Promise<void> => {
    stopPersisting(this.UserSettingsStore);
    this.UserSettingsStore = new UserSettingsStore(this, this.logger, 'UserSettingsStore');
    this.FormStore.setShouldAbortRequests(false);
    this.EquipmentsStore.setShouldAbortRequests(false);
    this.SharedListStore.setShouldAbortRequests(false);
    this.InboxesStore.setShouldAbortRequests(false);
    this.DeclarationStore.setShouldAbortRequests(false);
    this.QCScriptStore.setShouldAbortRequests(false);
    this.ComputeStore.setShouldAbortRequests(false);
    this.ConsentStore.setShouldAbortRequests(false);
  };
}

export default RootStore;

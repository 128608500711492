import React, { useState } from 'react';
import { CommonSimpleSelectable, WSActType } from '../../typesWorkSite';
import { useWorkSiteContext } from '../../WorkSiteSummaryWrapper';
import { AddNewUserDialogProps } from './ChooseNewOrExistingAddDialog';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';

type AddOneOrMultipleUsersDialogProps = {
  toggleUser: (e: React.MouseEvent<HTMLTableRowElement>, id: string) => void;
  users: Array<CommonSimpleSelectable & { agency: string }>;
  agencyList: Array<string>;
  filterAgency?: string;
  changeFilterAgency?: (e: React.SyntheticEvent, value: string) => void;
  nameFilter: string;
  filterPerName?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  addExistingUsers: () => void;
};

type SelectableUsersWithAgency = Array<CommonSimpleSelectable & { agency: string }>;

const useAddOneOrMultipleUsersDialogData = (props: AddNewUserDialogProps): AddOneOrMultipleUsersDialogProps => {
  const { WorkSiteStore } = useStore();
  const { workSite, setWorkSite } = useWorkSiteContext();
  const [agencyList, setAgencyList] = useState<Array<string>>([]);
  const [users, setUsers] = useState<SelectableUsersWithAgency>([]);
  const [filterAgency, setFilterAgency] = useState<string>(workSite.agency || '');
  const [nameFilter, setNameFilter] = useState<string>('');

  useEffectOnce(() => {
    WorkSiteStore.getAllUsers().then((res) =>
      setUsers(
        res.map((user) =>
          (workSite.users?.filter((wsUser) => wsUser.id === user.id) || []).length > 0
            ? {
                ...user,
                selected: true,
              }
            : { ...user, selected: false },
        ),
      ),
    );
    WorkSiteStore.getAllAgencyName().then((res) => setAgencyList(res));
  });

  function toggleUser(e: React.MouseEvent<HTMLTableRowElement>, id: string) {
    setUsers(users.map((user) => (user.id === id ? { ...user, selected: !user.selected } : user)));
  }

  function changeFilterAgency(e: React.SyntheticEvent, value: string) {
    setFilterAgency(value);
  }

  function filterPerName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setNameFilter(e.target.value);
  }

  function addExistingUsers() {
    setWorkSite({
      type: WSActType.ADDUSERS,
      value: users.filter((user) => user.selected).map((user) => ({ ...user, packs: [], roles: [] })),
    });
    props.onClose();
  }

  return {
    toggleUser,
    users,
    agencyList,
    filterAgency,
    changeFilterAgency,
    filterPerName,
    nameFilter,
    addExistingUsers,
  };
};

export default useAddOneOrMultipleUsersDialogData;

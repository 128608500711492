import React, { FunctionComponent } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { AddNewUserDialogProps } from './ChooseNewOrExistingAddDialog';
import useAddOneOrMultipleUsersDialogData from './hookAddOneOrMultipleUsersDialog';

const AddOneOrMultipleUsersDialog: FunctionComponent<AddNewUserDialogProps> = (props: AddNewUserDialogProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const {
    toggleUser,
    users,
    agencyList,
    filterAgency,
    changeFilterAgency,
    filterPerName,
    nameFilter,
    addExistingUsers,
  } = useAddOneOrMultipleUsersDialogData(props);

  return (
    <>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          {t('work_site_users_dialog_add_title')}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent data-cy="AddOneOrMultipleUsersDialog" style={{ minWidth: '600px' }}>
        <Stack gap={'0.5rem'}>
          <Stack direction={'row'} gap={'0.5rem'} marginTop={'0.5rem'}>
            <TextField
              fullWidth
              size={'small'}
              slotProps={{ input: { endAdornment: <SearchIcon /> } }}
              onChange={filterPerName}
              onBlur={filterPerName}
              label={t('work_site_action_search') || ''}
            />
            <Autocomplete
              fullWidth
              size={'small'}
              options={agencyList}
              defaultValue={filterAgency}
              onInputChange={changeFilterAgency}
              renderInput={(params) => <TextField {...params} label={t('work_site_summary_informations_agency')} />}
            />
          </Stack>
          <Table>
            {users
              .filter((user) => {
                return user.agency === filterAgency && user.name.toLowerCase().includes(nameFilter.toLowerCase());
              })
              .map((user) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => toggleUser(event, user.id)}
                    role="checkbox"
                    aria-checked={user.selected}
                    tabIndex={-1}
                    key={user.id}
                    selected={user.selected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={user.selected} inputProps={{ 'aria-labelledby': user.id }} />
                    </TableCell>
                    <TableCell component="th" id={user.id} scope="row" padding="none">
                      {user.name}
                    </TableCell>
                  </TableRow>
                );
              })}
          </Table>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} width={'100%'} spacing={2}>
          <Button fullWidth onClick={props.onClose} variant={'outlined'}>
            {t('work_site_action_cancel')}
          </Button>
          <Button fullWidth variant={'contained'} onClick={addExistingUsers}>
            {t('work_site_action_add')}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default AddOneOrMultipleUsersDialog;

import React, { Dispatch, FunctionComponent, SetStateAction, useContext, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Outlet } from 'react-router';
import { ChevronLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import WorkSiteTopBar, { TopBarProps } from '../../shared/TopBar/WorkSiteTopBar';
import useWorkSiteSummaryWrapperData, { WorkSiteSummaryProps } from './hookWorkSiteSummaryWrapper';

type TopBarSetter = { setRightElement: Dispatch<SetStateAction<TopBarProps['rightElement']>> };

const TopBarWorkSiteContext = React.createContext<TopBarSetter>({
  setRightElement: () => <></>,
});

export const useTopBarWorkSiteContextSetter = (): TopBarSetter['setRightElement'] => {
  return useContext(TopBarWorkSiteContext).setRightElement;
};

const WorkSiteContext = React.createContext<Partial<WorkSiteSummaryProps>>({
  workSite: undefined,
  setWorkSite: undefined,
});

export const useWorkSiteContext = (): WorkSiteSummaryProps => {
  const { workSite, setWorkSite } = useContext(WorkSiteContext);
  if (workSite === undefined || setWorkSite === undefined) {
    throw new Error('WorkSiteContext must be defined');
  }
  return { workSite, setWorkSite };
};

const WorkSiteSummaryWrapper: FunctionComponent = () => {
  const { workSite, setWorkSite, backPage, displayUnsavedDialog, confirmLossOfData, cancelLeavingWithoutSaving } =
    useWorkSiteSummaryWrapperData();
  const [topBarRightElement, setTopBarRightElement] = useState<TopBarProps['rightElement'] | undefined>();
  const { t } = useTranslation('common');

  const backButton = () => {
    return (
      <Button variant={'text'} startIcon={<ChevronLeft />} onClick={backPage} color={'secondary'}>
        {t('back')}
      </Button>
    );
  };

  const Content = useMemo(() => {
    return (
      <Box margin={'1rem 2rem'}>
        <Outlet />
      </Box>
    );
  }, []);

  if (workSite === undefined) {
    return <CircularProgress />;
  }
  return (
    <Box data-cy="WorkSiteSummaryWrapper">
      <TopBarWorkSiteContext.Provider value={{ setRightElement: setTopBarRightElement }}>
        <WorkSiteTopBar leftElement={backButton()} rightElement={topBarRightElement} />
        <WorkSiteContext.Provider value={{ workSite: workSite, setWorkSite: setWorkSite }}>
          {Content}
        </WorkSiteContext.Provider>
      </TopBarWorkSiteContext.Provider>
      <Dialog open={displayUnsavedDialog} onClose={cancelLeavingWithoutSaving}>
        <DialogTitle>There are unsaved changes</DialogTitle>
        <DialogActions>
          <Button variant={'contained'} onClick={cancelLeavingWithoutSaving}>
            Cancel
          </Button>
          <Button variant={'contained'} color={'error'} onClick={confirmLossOfData}>
            Leave without saving
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkSiteSummaryWrapper;

import { useState } from 'react';
import { GenericIdNameAttribute, WorkSiteProps } from '../../typesWorkSite';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

/*TODO KTY MOCK DATA (roles & packs name)*/
const mockRoles: Array<GenericIdNameAttribute> = [
  {
    id: 'OperatorId',
    name: 'Operator',
  },
  {
    id: 'QualityControlSpecialistId',
    name: 'Quality Control Specialist',
  },
  {
    id: 'SafetyTechnicianId',
    name: 'Safety Technician',
  },
  {
    id: 'RecruiterId',
    name: 'Recruiter',
  },
];

/* TODO KTY MOCK DATA */

type IdNameListProps = { url: string; worksite: WorkSiteProps };

const useIdNameList = (props: IdNameListProps): Array<GenericIdNameAttribute> => {
  const [idNameList, setIdNameList] = useState<Array<GenericIdNameAttribute>>([]);

  useEffectOnce(() => {
    // TODO Call api for real data
    switch (props.url) {
      case 'role':
        setIdNameList(mockRoles);
        return;
      case 'packs':
        setIdNameList(
          (
            props.worksite.standardPacks?.map((pack) => {
              return { id: pack.id, name: pack.name };
            }) || []
          ).concat(
            props.worksite.otherPacks?.map((pack) => {
              return { id: pack.id, name: pack.name };
            }) || [],
          ),
        );
    }
  });
  return idNameList;
};

export default useIdNameList;

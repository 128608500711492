import { AxiosError } from 'axios';
import { action, makeAutoObservable, observable } from 'mobx';
import { isPersisting, makePersistable } from 'mobx-persist-store';
import { PublicClientApplication } from '@azure/msal-browser';
import { TFunction } from 'i18next';
import mapMsalAuthConfig from '90.quickConnect.Models/mappings/others/mapAADConfig';
import { API_VALIDATE_TOKEN, API_SIGNIN } from '40.quickConnect.DataAccess/axios/apiRoutes';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { MsalAuthConfig } from '90.quickConnect.Models/models/user/MsalAuthConfig';
import RootStore from '30.quickConnect.Stores/RootStore';
import { MsalInfos } from '90.quickConnect.Models/models/user/msalConfig';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { errorHandler } from '80.quickConnect.Core/helpers';
import { SignInResponse, ValidateTokenResponse } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';

const defaultMsalInfos = {} as MsalInfos;

const defaultMsalAuthConfig = {
  auth: {
    clientId: '',
    clientSecret: '',
    redirectUri: '',
    authority: '',
  },
  cache: {
    cacheLocation: '',
    storeAuthStateInCookie: false,
  },
};

class LoginMsalStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/LoginMsalStore/index.ts';

  clientHTTP: IClientHTTP;

  logger: CustomLogger;

  RootStore: RootStore;

  msalInfos: MsalInfos = defaultMsalInfos;

  isLogoutByMsal = false;

  msalAuthConfig: MsalAuthConfig = defaultMsalAuthConfig;

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;

    makeAutoObservable(
      this,
      {
        msalInfos: observable,
        msalAuthConfig: observable,
        isLogoutByMsal: observable,
        setMsalAuthConfig: action,
        setIsLogoutByMsal: action,
      },
      {
        autoBind: true,
      },
    );

    void makePersistable(this, {
      name: storageKey,
      properties: ['msalInfos', 'msalAuthConfig', 'isLogoutByMsal'],
      storage: window.sessionStorage,
    });
  }

  get isPersisting() {
    return isPersisting(this);
  }

  resetStore = (): void => {
    this.msalInfos = defaultMsalInfos;
    this.msalAuthConfig = defaultMsalAuthConfig;
    this.isLogoutByMsal = false;
  };

  setMsalAuthConfig = (msalAuthConfig: MsalAuthConfig) => (this.msalAuthConfig = msalAuthConfig);

  setIsLogoutByMsal = (isLogoutByMsal: boolean): void => {
    this.isLogoutByMsal = isLogoutByMsal;
  };

  /**
   * Permet de créer la redirection dans le cas d'une connexion en SSO avec l'AAD.
   * @param msalInfos AADConfig providers d'identité de l'utilisateur au sein du portail Azure
   * @returns Promise<void>
   */

  redirectLogin = async (msalInfos: MsalInfos): Promise<void> => {
    try {
      this.logger.resetInitDateTimeApp();
      // Mémoriser les infos en parametres (utilisation de makePersistable)
      this.msalInfos = msalInfos;
      // Utiliser l'instance de Msal
      // Appeler la fonction loginRedirect de Msal
      if (this.isPersisting) {
        await this.handleRedirectPromise();
      }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error instanceof Error) {
        error.message = `La redirection Login MSAL a echoué: ${error.message}`;
        errorHandler(LoginMsalStore.TAG, error, 'redirectLogin');
      }
    }
  };

  handleRedirectPromise = async (): Promise<boolean> => {
    try {
      // Récupérer les informations du store
      this.setMsalAuthConfig(mapMsalAuthConfig(this.msalInfos));

      // LAncer l'instance pca
      const pca = new PublicClientApplication(this.msalAuthConfig);

      // Vérifions que l'account est présent (l'utilisateur s'est déjà connecté).
      const [existingAccount] = pca.getAllAccounts();

      if (existingAccount) {
        const accesTokenRequest = {
          scopes: ['openid'],
          account: existingAccount,
        };

        const accessTokenResponse = await pca.acquireTokenSilent(accesTokenRequest);

        const { idToken } = accessTokenResponse;

        // Appel à L'API validateToken
        return await this.validateTokenAsync(idToken);
      } else {
        // Lancer la methode HandleRedirectPromise du pca
        const response = await pca.handleRedirectPromise();

        if (response !== null) {
          this.logger.info(LoginMsalStore.TAG, 'account Utilisateur');

          const [account] = pca.getAllAccounts();

          const accesTokenRequest = {
            scopes: ['openid'],
            account,
          };

          const accessTokenResponse = await pca.acquireTokenSilent(accesTokenRequest);

          const { idToken } = accessTokenResponse;

          // Appel à L'API validateToken
          return await this.validateTokenAsync(idToken);

          // Display signed-in user content, call API, etc.
          // this.signInAsync();
        } else {
          // In case multiple accounts exist, you can select
          const currentAccounts = pca.getAllAccounts();

          if (currentAccounts.length === 0) {
            // no accounts signed-in, attempt to sign a user in

            await pca.loginRedirect({
              scopes: ['openid'],
              loginHint: this.msalInfos.userUPN,
            });
          }
        }
      }
    } catch (error) {
      errorHandler(LoginMsalStore.TAG, error, 'handleRedirectPromise');
    }
    return new Promise(() => false);
  };

  /**
   * Appelle l'API de QuickConnect avec un Bearer Token afin de se connecter.
   * @param idToken Token généré par le SSO d'Azure
   * @returns Promise<void>
   */
  validateTokenAsync = async (idToken: string): Promise<boolean> => {
    try {
      // Récupération d'un bearerToken
      const responseBearerToken = await this.clientHTTP.post(
        this.RootStore.CommonStore.chooseBaseUrl(API_VALIDATE_TOKEN),

        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      if (responseBearerToken.status < 200 || responseBearerToken.status >= 300) return false;

      // Récupération d'un QC Cookie
      const loginResponse = await this.clientHTTP.post<SignInResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_SIGNIN),
        {
          userUPN: this.msalInfos.userUPN,
          password: '',
        },
        {
          withCredentials: true,
        },
      );

      if (200 <= loginResponse.status && loginResponse.status < 300) {
        await this.logger.info(LoginMsalStore.TAG, `réponse du serveur ok pour la connexion: ${loginResponse.status}`);

        this.RootStore.LoginStore.setSignInInfos(loginResponse.data);
        return true;
      }
      return false;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<ValidateTokenResponse>;
        errorAxios.message = `La réponse serveur est KO pour la requête validateToken pour l'utilisateur ${
          this.msalInfos.userUPN
        }. Statut: ${errorAxios.response?.data.statusCode.toFixed()}`;
        errorHandler(LoginMsalStore.TAG, errorAxios, 'validateTokenAsync');
      } else {
        errorHandler(LoginMsalStore.TAG, error, 'validateToken');
      }
    }
    return false;
  };

  logoutMsalAsync = async () => {
    this.RootStore.LoginStore.setConnectByMsal(false);
    this.setIsLogoutByMsal(true);
    const pca = new PublicClientApplication(this.msalAuthConfig);

    const [account] = pca.getAllAccounts();

    const logoutRequest = {
      account,
    };

    await pca.logoutRedirect(logoutRequest);
  };

  logoutQCAsync = async (t: TFunction) => {
    this.setIsLogoutByMsal(false);
    this.RootStore.LoginStore.logOutAsync(t);
  };
}

export default LoginMsalStore;

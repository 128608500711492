import { FieldType } from '90.quickConnect.Models/enums';
import {
  AllFieldValueTypes,
  Choice,
  FieldData,
  FieldDesc,
  QCActionData,
  QCActionValueData,
} from '90.quickConnect.Models/models';

export const mapActionData = (field: FieldDesc): AllFieldValueTypes => {
  const { value } = field;
  let actionValue = new Array<QCActionValueData>();
  // Initialisation de la valeur de l'action si le champ n'était pas renseigné
  if (!Array.isArray(value)) {
    const emptyActionData = new Array<QCActionValueData>();
    const emptyActionFieldType =
      field.fieldType === FieldType.CheckBoxList ? FieldType.CheckBoxList : FieldType.RadioList;
    emptyActionData.push({
      fieldType: emptyActionFieldType,
      id: field.id,
      label: field.label ?? null,
      value: new Array<Choice>(),
    });
    actionValue = emptyActionData;
  } else {
    actionValue = value as QCActionData;
  }

  return actionValue.map((fieldData: FieldData) => {
    const { value: valueForActionValueData, fieldType, id, label } = fieldData as QCActionValueData;

    // Check if the value is not empty and not an array, convert it to an array
    let processedValue = valueForActionValueData;
    if (processedValue !== undefined && !Array.isArray(processedValue)) {
      processedValue = [processedValue];
    }
    const valueFormatted =
      fieldType === FieldType.CheckBoxList
        ? processedValue
        : Array.isArray(processedValue) && processedValue.length === 1
        ? processedValue[0]
        : null;

    return {
      fieldType,
      id,
      label,
      value: valueFormatted,
    };
  });
};

import React, { FunctionComponent } from 'react';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import NavigationButtons from './SubPartsAndUsers/NavigationButtons';
import { useWorkSiteContext } from './WorkSiteSummaryWrapper';
import PackList from './Packs/PackList';
import ReportList from './Reports/ReportList';
import SaveButton from './TopBar/SaveButton';

const ContainerStyle = styled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    padding: '0.1rem',
    '& .content': {
      backgroundColor: theme.palette.background.default,
      margin: '0.4rem',
      padding: '0.6rem',
      borderRadius: theme.shape.borderRadius,
    },
    '& .title': {
      fontWeight: theme.typography.fontWeightBold,
    },
  };
});

const WorkSiteSummary: FunctionComponent = () => {
  const { workSite, setWorkSite } = useWorkSiteContext();
  const { t } = useTranslation('moduleWorkSite');

  function getTopLeftContent() {
    return (
      <>
        <Typography className={'title'} variant={'h5'}>
          {t('work_site_summary_informations')}
        </Typography>
        <GeneralInfo workSite={workSite} setWorkSite={setWorkSite} />
      </>
    );
  }

  function getBottomLeftContent() {
    return (
      <>
        <Typography className={'title'} variant={'h5'}>
          {t('work_site_summary_workforce_and_documents')}
        </Typography>
        <NavigationButtons />
      </>
    );
  }

  function getCenterTopContent() {
    return (
      <>
        <Typography className={'title'} variant={'h5'}>
          {t('work_site_summary_standards_packs')}
        </Typography>
        <PackList packs={workSite.standardPacks} setWorkSite={setWorkSite} />
      </>
    );
  }

  function getCenterBottomContent() {
    return (
      <>
        <Typography className={'title'} variant={'h5'}>
          {t('work_site_summary_other_packs')}
        </Typography>
        <PackList packs={workSite.otherPacks} setWorkSite={setWorkSite} />
      </>
    );
  }

  function getRightContent() {
    return (
      <>
        <Typography className={'title'} variant={'h5'}>
          {t('work_site_summary_reports')}
        </Typography>
        <ReportList workSite={workSite} setWorkSite={setWorkSite} />
      </>
    );
  }

  return (
    <Box data-cy="WorkSiteSummary">
      <Grid container spacing={1}>
        <Grid size={3}>
          <ContainerStyle>
            <Box className={'content'}>{getTopLeftContent()}</Box>
            <Box className={'content'}>{getBottomLeftContent()}</Box>
          </ContainerStyle>
        </Grid>
        <Grid size={9}>
          <ContainerStyle>
            <Grid columns={9} container>
              <Grid size={6}>
                <Box className={'content'}>{getCenterTopContent()}</Box>
                <Box className={'content'}>{getCenterBottomContent()}</Box>
              </Grid>
              <Grid size={3}>
                <Box className={'content'}>{getRightContent()}</Box>
              </Grid>
            </Grid>
          </ContainerStyle>
        </Grid>
      </Grid>
      <Outlet />
      <SaveButton />
    </Box>
  );
};

export default WorkSiteSummary;

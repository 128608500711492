import { ChangeEvent, useState } from 'react';
import { WorkSiteSummaryProps } from '../hookWorkSiteSummaryWrapper';
import { WSActType } from '../typesWorkSite';
import { useStore } from '30.quickConnect.Stores';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

type GeneralInfoProps = {
  workSiteNameList: Array<string>;
  agencyList: Array<string>;
  onUserFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  deleteUserFile: () => void;
};

const useGeneralInfo = (props: WorkSiteSummaryProps): GeneralInfoProps => {
  const { WorkSiteStore } = useStore();
  const [workSiteNameList, setWorkSiteNameList] = useState<Array<string>>([]);
  const [agencyList, setAgencyList] = useState<Array<string>>([]);

  /**  TODO KTY appeler la liste des chantiers possibles
   * quand en édition d'un chantier, disable le worksite name car utilsable comme ID
   * **/
  useEffectOnce(() => {
    WorkSiteStore.getAllWorkSiteName().then((workSiteList) => {
      setWorkSiteNameList(workSiteList);
    });
  });

  useEffectOnce(() => {
    WorkSiteStore.getAllAgencyName().then((workSiteList) => {
      setAgencyList(workSiteList);
    });
  });

  const onUserFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      props.setWorkSite({ type: WSActType.LOGO, value: files[0] });
    }
  };

  const deleteUserFile = () => {
    props.setWorkSite({ type: WSActType.LOGO, value: undefined });
  };

  return { workSiteNameList, agencyList, onUserFileUpload, deleteUserFile };
};

export default useGeneralInfo;

import React, { FunctionComponent } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { AddNewUserDialogProps } from './ChooseNewOrExistingAddDialog';

const CreateNewUserDialog: FunctionComponent<AddNewUserDialogProps> = (props: AddNewUserDialogProps) => {
  // TODO KTY connect all inputs
  const { t } = useTranslation('moduleWorkSite');
  return (
    <>
      <DialogTitle data-cy="AddNewUserDialog">
        <Stack direction={'row'} justifyContent={'space-between'}>
          {t('work_site_users_dialog_create_title')}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextField label={t('work_site_users_dialog_create_last_name')} fullWidth />
          </Grid>
          <Grid size={6}>
            <TextField label={t('work_site_users_dialog_create_first_name')} fullWidth />
          </Grid>
          <Grid size={12}>
            <TextField label={t('work_site_users_dialog_create_email')} fullWidth />
          </Grid>
          <Grid size={12}>
            <TextField label={t('work_site_users_dialog_create_login')} fullWidth />
          </Grid>
          <Grid size={6}>
            <TextField label={t('work_site_users_dialog_create_phone')} fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} width={'100%'} spacing={2}>
          <Button fullWidth onClick={props.onClose} variant={'outlined'}>
            {t('work_site_action_cancel')}
          </Button>
          <Button fullWidth variant={'contained'}>
            {t('work_site_action_add')}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default CreateNewUserDialog;

export enum PackStatus {
  TOCOMPLETE,
  WAITING,
  SYNCING,
  COMPLETED,
}

type GenericIdAttribute = {
  id: string;
};

export interface GenericIdNameAttribute extends GenericIdAttribute {
  name: string;
}

export interface Form extends GenericIdNameAttribute {
  completed: boolean;
}

export interface CommonSimpleSelectable extends GenericIdNameAttribute {
  selected: boolean;
}

export interface Pack extends CommonSimpleSelectable {
  status: PackStatus;
  nbForm: number;
}

export interface Report extends CommonSimpleSelectable {
  description: string;
  nbMissingReports: number;
  previewPictures: Array<string>; // Url Array
  forms: { [key: string]: Array<Form> }; // Sorted by pack
}

export interface User extends GenericIdNameAttribute {
  roles: Array<GenericIdNameAttribute['id']>; // Id Array
  packs: Array<GenericIdNameAttribute['id']>; // Id Array
}

export interface Member extends GenericIdNameAttribute {
  login: string;
}

export type WorkSiteProps = {
  //General Info
  id?: string;
  name?: string;
  agency?: string;
  postalCode?: string;
  sharePointSiteName?: string;
  sharePointLibraryName?: string;
  logo?: string | File;

  //Packs
  standardPacks?: Array<Pack>;
  otherPacks?: Array<Pack>;

  //Reports
  reports?: Array<Report>;

  //Users
  users?: Array<User>;

  //Members
  members?: Array<Member>;

  // Front attrs
  hasChanged: boolean;
  // TODO KTY append the data here for more props, all optional, will be used as local storage too
};

type KeyOfAttrWithId<T> = {
  [K in keyof T]: NonNullable<T[K]> extends Array<GenericIdAttribute> ? K : never;
}[keyof T];

type KeyOfArrayOfString<T> = {
  [K in keyof T]: NonNullable<T[K]> extends Array<string> ? K : never;
}[keyof T];

// WS: WorkSite
export enum WSActType {
  ID,
  NAME,
  AGENCY,
  POSTCODE,
  SHAREPOINTSITENAME,
  SHAREPOINTLIBRARYNAME,
  LOGO,

  //Packs
  PACKSINGLE,
  STANDARDPACKS,
  OTHERPACKS,
  SELECTPACK,

  //Reports
  REPORTS,
  SELECTREPORT,

  //USERS
  CREATEUSERS,
  ADDUSERS,
  REMOVEUSER,
  SELECTUSERROLE,
  SELECTUSERPACK,

  //Front end
  HASCHANGED,
  RESET,
}

// No value
type ActWSReset = { type: WSActType.RESET };

// string
type ActWSString = {
  type:
    | WSActType.ID
    | WSActType.NAME
    | WSActType.AGENCY
    | WSActType.POSTCODE
    | WSActType.SHAREPOINTSITENAME
    | WSActType.SHAREPOINTLIBRARYNAME;
  value?: string;
};
// Select/Unselect
type ActWSPackSelect = {
  type: WSActType.SELECTPACK | WSActType.SELECTREPORT;
  value: { id: CommonSimpleSelectable['id']; selected: boolean };
};
// File
type ActWSFile = { type: WSActType.LOGO; value?: File };
// Arrays
type ActWSPackArray = { type: WSActType.STANDARDPACKS | WSActType.OTHERPACKS; value: Array<Pack> };
type ActWSReportArray = { type: WSActType.REPORTS; value: Array<Report> };
type ActWSUserArray = { type: WSActType.CREATEUSERS; value: Array<User> };
// boolean
type ActWSHasChanged = { type: WSActType.HASCHANGED; value: boolean };

// Pack
type ActWSPack = { type: WSActType.PACKSINGLE; value: { id: Pack['id']; pack: Partial<Pack> } };

// User
type ActWSUsersAdd = { type: WSActType.ADDUSERS; value: Array<User> };
type ActWSUserRmv = { type: WSActType.REMOVEUSER; value: User['id'] };
type ActWSUserSelectRole = {
  type: WSActType.SELECTUSERROLE | WSActType.SELECTUSERPACK;
  value: { userId: User['id']; id: GenericIdNameAttribute['id'] };
};

export type ActWS =
  | ActWSReset
  | ActWSString
  | ActWSFile
  | ActWSPackArray
  | ActWSPack
  | ActWSPackSelect
  | ActWSReportArray
  | ActWSUserArray
  | ActWSUsersAdd
  | ActWSUserRmv
  | ActWSUserSelectRole
  | ActWSHasChanged;

const changePackAttribute = (workSite: WorkSiteProps, packId: Pack['id'], value: Partial<Pack>) => {
  let key: 'standardPacks' | 'otherPacks' = 'standardPacks';
  let packIdx = workSite.standardPacks && workSite.standardPacks.findIndex((p) => p.id === packId);
  if (packIdx === -1 && workSite.otherPacks) {
    key = 'otherPacks';
    packIdx = workSite.otherPacks.findIndex((p) => p.id === packId);
  }

  if (packIdx === -1) {
    throw Error(`Unable to find pack with Id ${packId}`);
  }

  const array =
    (workSite?.[key] as WorkSiteProps['standardPacks'] | WorkSiteProps['otherPacks'])?.map((element, idx) => {
      if (idx === packIdx) {
        return { ...element, ...value };
      }
      return element;
    }) || workSite?.[key];
  return { ...workSite, [key]: array };
};

function changeAttributeById<K extends WorkSiteProps>(
  workSite: K,
  id: GenericIdNameAttribute['id'],
  value: K[KeyOfAttrWithId<K>],
  keyToChange: KeyOfAttrWithId<K>,
): WorkSiteProps {
  if (!keyToChange) {
    return workSite;
  }
  const array = (workSite[keyToChange] as Array<GenericIdNameAttribute>).map((element) => {
    if (element.id === id) {
      return { ...element, ...value };
    }
    return element;
  });

  return { ...workSite, [keyToChange]: array };
}

function addOrDeleteIdInSubList<K extends User>(
  arrayToMutate: Array<K>,
  idToMutate: K['id'],
  internalKeyToChange: KeyOfArrayOfString<K>,
  idToAddOrDelete: string,
) {
  return arrayToMutate?.map((user) => {
    if (user.id === idToMutate) {
      // Remove if present
      const userListToMutate: Array<string> = user[internalKeyToChange] as Array<string>;
      if (userListToMutate.includes(idToAddOrDelete)) {
        return { ...user, [internalKeyToChange]: userListToMutate.filter((roleId) => roleId !== idToAddOrDelete) };
      }
      //Add if not
      return { ...user, [internalKeyToChange]: userListToMutate.concat([idToAddOrDelete]) };
    }
    return user;
  });
}

export const workSiteReducer = (state: WorkSiteProps, action: ActWS): WorkSiteProps => {
  const modifiedState = { ...state, hasChanged: true };

  function removeDuplicateById<T extends GenericIdAttribute>(list?: Array<T>): Array<T> {
    const ids = new Set(); // temp variable to keep track of accepted ids
    return list?.filter(({ id }) => !ids.has(id) && ids.add(id)) || [];
  }

  function sortByName<T extends GenericIdNameAttribute>(list?: Array<T>): Array<T> {
    return list?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }

  switch (action.type) {
    case WSActType.RESET:
      return { id: state.id, hasChanged: false };
    case WSActType.ID:
      return action.value === state.id ? state : { ...modifiedState, id: action.value };
    case WSActType.NAME:
      return action.value === state.name ? state : { ...modifiedState, name: action.value };
    case WSActType.AGENCY:
      return action.value === state.agency ? state : { ...modifiedState, agency: action.value };
    case WSActType.POSTCODE:
      return action.value === state.postalCode ? state : { ...modifiedState, postalCode: action.value };
    case WSActType.SHAREPOINTSITENAME:
      return action.value === state.sharePointSiteName ? state : { ...modifiedState, sharePointSiteName: action.value };
    case WSActType.SHAREPOINTLIBRARYNAME:
      return action.value === state.sharePointLibraryName
        ? state
        : {
            ...modifiedState,
            sharePointLibraryName: action.value,
          };
    case WSActType.LOGO:
      return action.value === state.logo ? state : { ...modifiedState, logo: action.value };
    case WSActType.STANDARDPACKS:
      return action.value === state.standardPacks ? state : { ...modifiedState, standardPacks: action.value };
    case WSActType.OTHERPACKS:
      return action.value === state.otherPacks ? state : { ...modifiedState, otherPacks: action.value };
    case WSActType.SELECTPACK:
      return changePackAttribute(modifiedState, action.value.id, { selected: action.value.selected });
    case WSActType.REPORTS:
      return action.value === state.reports ? state : { ...modifiedState, reports: action.value };
    case WSActType.SELECTREPORT:
      return changeAttributeById(modifiedState, action.value.id, { selected: action.value.selected }, 'reports');
    case WSActType.CREATEUSERS:
      return action.value === state.users ? state : { ...modifiedState, users: action.value };
    case WSActType.ADDUSERS:
      return {
        ...modifiedState,
        users: removeDuplicateById(sortByName(modifiedState.users?.concat(action.value))),
      };
    case WSActType.REMOVEUSER:
      return { ...modifiedState, users: modifiedState.users?.filter((user) => user.id !== action.value) };
    case WSActType.SELECTUSERROLE:
      const newUserListRoles = addOrDeleteIdInSubList(
        modifiedState.users || [],
        action.value.userId,
        'roles',
        action.value.id,
      );
      return { ...modifiedState, users: newUserListRoles };
    case WSActType.SELECTUSERPACK:
      const newUserListPacks = addOrDeleteIdInSubList(
        modifiedState.users || [],
        action.value.userId,
        'packs',
        action.value.id,
      );
      return { ...modifiedState, users: newUserListPacks };
    case WSActType.HASCHANGED:
      return action.value === state.hasChanged ? state : { ...modifiedState, hasChanged: action.value };
    default:
      return modifiedState;
  }
};
